<script setup lang="ts">
import type {CmsElementProductSlider} from "@shopware-pwa/composables-next";
import {useCmsElementConfig} from "@shopware-pwa/composables-next";
import {CmsSlot} from "@shopware-pwa/types";
import {SearchIndex} from "algoliasearch";
import {SharedProductSliderConfig} from "~/types/SharedProductSliderConfig";

const props = defineProps<{
    content: CmsElementProductSlider;
}>();

const {getConfigValue} = useCmsElementConfig(props.content as CmsSlot & { config: CmsSlot["config"] });
const {getTopSellerIndexName} = useAlgolia();

const filtersStore = useFiltersStore();
const facetsStore = useFacetsStore();
const products = ref([]);
const indexName = useRuntimeConfig().public.algoliaDev.productIndexName;
const algolia = useAlgoliaRef();
const index = algolia.initIndex(indexName) as SearchIndex;
const visibilityFilter = "visibilities.ca7a8b4761a844be94f9cbe265179d6a:30";
const streamFilter = ref("");
const filters = ref([visibilityFilter]);
const asyncDataName = ref('ssr-search-results');
const linkToCategory = ref("");
const streamId = props.content?.data?.streamId;
const globalFilters = props.content?.config?.globalFilters?.value;

if (props.content?.config?.linkToCategory?.value) {
    linkToCategory.value = props.content?.config?.linkToCategory?.value
}

if (streamId) {
    asyncDataName.value = asyncDataName.value + '-' + streamId
    streamFilter.value = 'streamIds:' + streamId
    filters.value.push(streamFilter.value);
}
const refinements = [
    'filterableProperties.Kategori',
    'filterableProperties.Alkohol',
    'filterableProperties.Certificering',
    'filterableProperties.Drue',
    'filterableProperties.Emballage',
    'filterableProperties.Land',
    'filterableProperties.Mærke',
    'filterableProperties.Region',
    'filterableProperties.Størrelse',
    'filterableProperties.Årgang'
];
const { search } = useAlgoliaSearch(getTopSellerIndexName())
const nbHits = ref(0);
const algoliaData = ref(<any>null);
algoliaData.value = await getAlgoliaProducts(filters.value);
async function getAlgoliaProducts (filterArray) {
    const requestOptions = {facetFilters: filterArray};
    if (globalFilters) {
        requestOptions['facets'] = refinements;
    }
    const { data } = await useAsyncData(
        asyncDataName.value,
        () => search({ query: '', requestOptions: requestOptions })
    );
    if (data.value?.nbHits) {
        nbHits.value = data.value.nbHits;
    }
    if (!globalFilters) {
        return data.value;
    }
    const facets = data.value?.facets;
    if (facets && streamId && !facetsStore.loadedStreamIds.includes(streamId)) {
        if (facetsStore.getAvailableFacets()) {
            for (const facet in facets) {
                for (const item in facets[facet]) {
                    facetsStore.addAvailableFacet(facet, item, facets[facet][item]);
                }
            }
        } else {
            facetsStore.setAvailableFacets(facets);
        }
        facetsStore.addStreamId(streamId);
    }

    return data.value;
}

onBeforeMount(async () => {
    if (algoliaData.value?.hits?.length > 0) {
        products.value = algoliaData.value?.hits.filter((product) => product !== null);
    }
});

const autoplay = computed(() => getConfigValue("rotate"));
const title = computed(() => getConfigValue("title"));
const border = computed(() => getConfigValue("border"));

const config: SharedProductSliderConfig = {
    title: title.value,
    autoplay: autoplay.value,
    border: border.value,
    layoutType: getConfigValue("boxLayout"),
    displayMode: getConfigValue("displayMode"),
    elMinWidth: getConfigValue("elMinWidth"),
    link: linkToCategory.value,
    nbHits: nbHits.value
}
if (globalFilters) {
    filtersStore.$subscribe(async (mutation, state) => {
        filters.value = [visibilityFilter, streamFilter.value, ...state.activeFilters];
        algoliaData.value = await getAlgoliaProducts(filters.value);
        products.value = await algoliaData.value?.hits.filter((product) => product !== null);
    });
}
</script>
<template>
    <div class="h-[420px]">
        <SharedProductSlider
            :products="products"
            :config="config"
        />
    </div>
</template>
